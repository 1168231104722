<template>
  <div class="meetSetting">
    <div class="header">
      <div class="name">{{ $t("seeting.headPortrait") }}</div>
       <img class="img" :src="userInfo.avatar?userInfo.avatar:require('../../../assets/images/headerImg.png')" alt="" />
    </div>
    <div class="item" v-if="userInfo.packageType === 2">
      <div class="tit">{{ $t("seeting.mailbox") }}</div>
      <div class="text">{{ userInfo.email }}</div>
    </div>
    <div class="item" v-else>
      <div class="tit">{{ $t("seeting.phone") }}</div>
      <div class="text">{{ userInfo.phone }}</div>
    </div>

    <div class="item" @click="go('changeUserName')">
      <div class="tit">{{ $t("seeting.changeName") }}</div>
      <div class="box">
        <div class="text">{{ userInfo.realName }}</div>
        <img class="rightImg" src="../../../assets/images/right.png" alt="" />
      </div>
    </div>
    <div class="button">
      <van-button size="large" @click="back" class="btn" type="default">{{
        $t("common.back")
      }}</van-button>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
  },
  methods: {
    go(path) {
      this.$router.push({ name: path });
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang='less'>
.meetSetting {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fafafa;
  .rightImg {
    width: 32px;
    height: 32px;
    margin-left: 16px;
  }
  .text {
    font-size: 28px;
    color: #999;
  }
  .header {
    height: 200px;
    margin-top: 16px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;

    .img {
      width: 110px;
      height: 110px;
    }
    .info {
      margin-left: 20px;
    }
    .name {
      font-size: 32px;
      color: #000;
    }
    .tel,
    .email {
      font-size: 28px;
      color: #999;
    }
  }
  .button {
    width: 100%;
    box-sizing: border-box;
    padding: 0 64px;
    margin-top: 80px;
    .btn {
      height: 88px;
    }
  }
  .item {
    background: #fff;
    margin-top: 16px;
    height: 104px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    .tit {
      font-size: 32px;
      color: #333;
    }
    .box {
      display: flex;
      align-items: center;
    
    .text{
      display: inline-block;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; //溢出不换行
      width:500px;
    }
    }
  }
}
</style>
